<template>
	<div class="bg-F7F7F7 mh100">
		<div class="paddt20">
			<div class="w1200 margin-center">
				<div class="padd20 bg-white display-flex">
					<img class="goods-pic display-block" :src="orderDetail.goods_image" alt="" />
					<div class="flex-1 marl20 h104 justify-content-center-between">
						<div class="h104 flex-column justify-content-between">
							<div>
								<div class="fz20 co-333333 font-blod">{{orderDetail.title || ''}}</div>
								<div class="fz14 co-333333  mart12">{{orderDetail.order_type_text}}</div>
							</div>
							<div class="align-items-center">
								<span class="fz20 co-010085">{{orderDetail.good_price || ''}}</span>
								<span class="fz20 co-999999  marl20">在线支付</span>
							</div>
						</div>
						<div class="align-items-center">
							<span class="fz16 co-333333 marr90">x{{orderDetail.num ||''}}</span>
							<div class=" fz16 display-block marr90"
								:class="orderDetail.status == 1? 'co-FE3000' : orderDetail.status == 2||orderDetail.status == 5 ? 'co-32B16C' : 'co-333333'">
								{{orderDetail.status_text}}
							</div>
							<div class="cure-pointer">
								<div class="btn-01 center fz14 co-010085 marb10" @click.stop='buy(orderDetail)'
									v-if="orderDetail.status == 1">立即购买</div>
								<div class="btn-02 center fz14 co-333333"
									v-if="orderDetail.status == 2 || orderDetail.status == 3"
									@click.stop="cancel($event, 3, i)">删除订单</div>
								<div class="btn-01 center fz14 co-010085 marb10"
									v-if="orderDetail.status == 4 && titleIndex == 1"
									@click.stop="cancel($event, 4, i)">课程评价
								</div>
								<div class="btn-02 center fz14 co-333333" v-if="orderDetail.status == 1"
									@click.stop="cancel($event, 1, i)">取消订单</div>
							</div>
						</div>
					</div>
				</div>

				<div class="bg-white mart10" v-if="orderDetail.address">
					<div class="h51 fz16 co-333333 align-items-center paddlr20">收货地址</div>
					<div class="h40 fz14 co-333333 align-items-center paddlr20">
						<span>{{orderDetail.address.name}}</span>
						<span class="display-block marl46">{{orderDetail.address.phone}}</span>
						<span class="display-block marl46">{{orderDetail.address.address}}</span>
					</div>
				</div>

				<div class="bg-white mart10" v-if="orderDetail.gift&&orderDetail.gift.name">
					<div class="h54 fz16 co-333333 justify-content-center-between paddlr20">
						<span class="fz16 co-333333 font-blod">赠品</span>
						<span class="fz14 co-333333">{{orderDetail.gift.name || ''}}</span>
					</div>

				</div>
				<div class="bg-white mart10" v-else>
					<div class="h54 fz16 co-333333 justify-content-center-between paddlr20">
						<span class="fz16 co-333333 font-blod">赠品</span>
						<span class="fz14 co-333333">无</span>
					</div>
				
				</div>
				<div v-if="orderDetail.status==4">
					<div class="bg-white mart10 paddlr20" v-if='orderDetail.type=="teacher"'>
						<div class="h62 fz16 co-333333 justify-content-center-between font-blod">课程评价</div>
						<div class="content-area">
							<textarea class="w100 h100 textarea" v-model="content" placeholder="感觉怎么样？分享一下吧"></textarea>
						</div>
						<div class="w100 justify-content-end fz14 co-010085 paddb20 mart20 paddr20 cure-pointer " @click="submit">
							<span class='fz16 paddr20' >提交</span>
						</div>
					</div>
				</div>
				

				<div class="padd20 bg-white mart10">
					<div class="fz16 co-333333 font-blod">订单信息</div>
					<div class="align-items-center mart10 h40">
						<span class="fz14 co-333333">订单编号</span>
						<span class="fz14 co-333333 display-block marl46">{{orderDetail.order_no || ''}}</span>
					</div>
					<div class="align-items-center h40">
						<span class="fz14 co-333333">创建时间</span>
						<span class="fz14 co-333333 display-block marl46">{{orderDetail.create_time || ''}}</span>
					</div>
					<div v-if="orderDetail.pay_time">
						<div class="align-items-center h40" v-if="orderDetail.pay_time">
							<span class="fz14 co-333333">付款时间</span>
							<span class="fz14 co-333333 display-block marl46">{{orderDetail.pay_time ||''}}</span>
						</div>
						<div class="align-items-center h40">
							<span class="fz14 co-333333">支付方式</span>
							<span class="fz14 co-333333 display-block marl46">在线支付</span>
						</div>
						<div class="align-items-center h40">
							<span class="fz14 co-333333">支付金额</span>
							<span class="fz14 co-FE3000 display-block marl46">￥{{orderDetail.pay_price ||'0.00'}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="选择支付方式" v-model="dialogFormVisible" :center='true' :width='600'>
			<div class="display-flex">
				<div class="flex-1 flex-column align-items-center justify-content-center cure-pointer"
					@click="choosepay(i)" v-for="(e,i) in wayList" :key="i">
					<img class="head-pic " :src="e.icon" alt="" />
					<div class="#co-333333 fz16 mart10">
						{{e.txt}}
					</div>
				</div>

			</div>
		</el-dialog>
		<el-dialog title="微信支付" v-model="pay" :center='true' :width='600'>
			<div class="puops">
				<div class="qrcode-box" ref="payqrcodes"></div>
				<p>请使用手机打开微信扫描二维码完成支付</p>
			</div>
		</el-dialog>
		<!-- 确认取消弹窗 -->
		<SureCancelPopup ref="SureCancelPopup" @sureBtn="sureBtn" content="是否确认删除订单"></SureCancelPopup>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
</template>

<script>
		import QRCode from 'qrcodejs2';
	import {
		useRoute
	} from 'vue-router';

	export default {
		name: 'OrderDetail',
		data() {
			return {
				pay: false,
				dialogFormVisible: false,
				fullscreenLoading: true, //支付加载
				loading: false, // 接口加载状态
				status: -1, // 订单状态
				status_text: '', // 订单状态文字
				type: -1, // 0课程 1名师 2素材
				btnStyle: {

					buttonText: '立即付款'
				}, // 按钮样式
				orderDetail: {}, //订单信息
				dataType: '',
				downLoadUrl: '',
				order_id: "", //查询订单
				oredrid: "",
				wayList: [{
						txt: '微信支付',
						icon: require('@/assets/img/sub_02.png'),
						value: 1,
					},
					// {
					// 	txt: '支付宝支付',
					// 	icon: require('@/assets/img/sub_01.png'),
					// 	value: 2,
					// },
				],
				content:""
			}
		},
		created() {
			let id = useRoute().query.id
			this.$req({
				method: 'get',
				url: '/api/mapleOrder/detail',
				data: {
					id: id,
				},
				success: res => {
					this.fullscreenLoading = false;
					console.log(res, '页面详情')
					this.orderDetail = res;
				},
				fail: error => {}
			});


		},
		methods: {
			submit() {
				if(!this.content) {
					this.$message('评论内容不能为空')
					return
				}
				this.$req({
					method: 'post',
					url: '/api/comment',
					data:{
						id:this.orderDetail.course_id,
						content:this.content,
						comment_id:'',
						type:'teacher',
					},
					success: res => {
						this.content = '';
						this.$router.go(-1)
						this.$message({
							type: 'success',
							message: '评价成功!'
						
						});
					},
					fail: error => {
						this.$message(error.data.message);
					}
				});
			},
			buy(item) {
				this.oredrid = item.id;
				this.dialogFormVisible = !this.dialogFormVisible;
			},
			choosepay(e) {
				this.way = e;
				this.dialogFormVisible = !this.dialogFormVisible;
				this.fullscreenLoading = true;
				this.$req({
					method: 'post',
					data: {
						order_id: this.oredrid,
						pay_type: this.wayList[this.way].value,
						trade_type: 3
					},
					url: '/api/mapleOrder/pay',
					success: res => {
						this.pay = !this.pay;
						this.fullscreenLoading = false;
						if (res) {
							let url = res.code_url; // 微信支付链接
							this.order_id = res.order_id
							if (this.wayList[this.way].value == 1) {
								this.pay = true;
								window.requestAnimationFrame(() => {
									this.fullscreenLoading = false;
									this.$refs.payqrcodes.innerHTML = '';
									//支付二维码
									let qrcode = new QRCode(this.$refs.payqrcodes, {
										text: url, // 需要转换为二维码的内容
										width: 150,
										height: 150,
										colorDark: '#000000',
										colorLight: '#ffffff',
										correctLevel: QRCode.CorrectLevel.H
									})
									this.getOrderStatus(); // 获取订单详情																
								})
							} else {
								this.fullscreenLoading = false;
								let html = res;
								console.log(html)
								const div = document.createElement('divform');
								div.innerHTML = html;
								document.body.appendChild(div);
								// document.forms[0].acceptCharset='GBK'; //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
								document.forms[0].submit();
						
							}
						
						}
					},
					fail: error => {
						this.fullscreenLoading = false;
						this.pay = !this.pay;
					}
				});
			},
			getOrderStatus() {

				this.$req({
					method: 'get',
					url: '/api/mapleOrder/detail',
					data: {
						id: this.order_id
					},
					success: res => {
						let data = res;
						if (data.status > 1) {
							this.$message.success("支付成功");
							this.pay = false;
							window.requestAnimationFrame(() => {
								this.request.tokenSuccess();
							})
							location.reload();
						} else if (data.status < 0) {
							this.pay = false;
							this.$message.warning("订单已取消");
						} else if (this.pay) {
							let a = setTimeout(() => {
								clearTimeout(a);
								this.getOrderStatus(); // 获取订单详情
							}, 2000)
						}
					},
					fail: error => {

					}
				});
			},
			cancel(e, type, i) {
				if (type == 1) {
					this.$confirm('确定取消此订单么?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$req({
							method: 'put',
							url: '/api/mapleOrder/close',
							data: {
								id: this.orderDetail.id,
							},
							success: res => {
								this.$router.go(-1)
								this.$message({
									type: 'success',
									message: '取消成功!'

								});
							},
							fail: error => {}
						});

					}).catch(() => {

					});
				} else if (type == 3) {
					this.$confirm('确定删除此订单么?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$req({
							method: 'delete',
							url: '/api/mapleOrder/delete',
							data: {
								id: this.orderDetail.id,
							},
							success: res => {
								this.$message({
									type: 'success',
									message: '删除成功!'

								});
								this.$router.go(-1)
							},
							fail: error => {}
						});

					}).catch(() => {

					});


				} else if (type == 4) {
					// 课程评价
					// 携带评价参数
					// getApp().globalData.evaluateInfo = this.list[i]
					// uni.navigateTo({
					// 	url: '../classEvaluate/classEvaluate',
					// })
				}

				e.stopPropagation()
			},
		}
	}
</script>

<style lang="less" scoped>
	.head-pic{
		width: 50px;
		height: 50px;
	}
	.puops {
		display: flex;
		align-items: center;
		min-height: 316px;
		flex-direction: column;
	
		h2 {
			width: 100%;
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			color: #131313;
			border-bottom: 1px solid #d7dae2;
			padding-bottom: 22.5px;
		}
	
		.order_pay {
			display: flex;
			flex-direction: column;
			background-color: #e5e5e5;
			width: 100%;
			padding: 21px 35px;
		}
	
		.qrcode-box {
			margin: 40px 0;
			width: 150px;
			height: 150px;
			background-color: #f9f9f9;
			overflow: hidden;
	
			canvas+img {
				width: 100%;
				height: 100%;
			}
		}
	
		p {
			font-size: 19px;
			font-weight: 500;
			color: #E84516;
			opacity: 0.5;
		}
	}
	.goods-pic {
		width: 140px;
		height: 104px;
	}

	.btn-02 {
		width: 70px;
		height: 26px;
		border: 1px solid #999999;
		opacity: 1;
		border-radius: 3px;
	}

	.btn-01 {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		opacity: 1;
		border-radius: 3px;
	}

	.content-area {
		width: 1136px;
		padding: 12px;
		height: 84px;
		opacity: 1;
		border-radius: 8px;
		background: #F7F7F7;
	}

	.textarea {
		resize: none;
		outline: none;
		border: 0;
		padding: 0;
		margin: 0;
		background: #F7F7F7;
	}

	.gift-item {
		width: 185px;
		height: 34px;
		background: #F7F7F7;
	}

	.gift-item:nth-child(6n) {
		margin-right: 0;
	}

	textarea::-webkit-input-placeholder {
		color: #999999;
		font-size: 14px;
	}
</style>
